/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import urljoin from 'url-join'
import SEO from '@components/SEO'
import { parseCreditCard } from '@services/prismic/helpers'
import { linkResolver } from '@utils/link-resolver'
import Layout from '@layouts/NoHeaderLayout'
import Container from '@layouts/Container'
import Box from '@lib/ui/core/Box'
import JsonLd from '@components/JsonLd'
import { addGaEvent } from '@utils/ga-event'
import articleSchema from '@utils/json-ld/article'
import AppLink from '@components/AppLink'
import SearchInput from "@components/Search/SearchInput"
import CreditCardDetail from "@components/CreditCard/Detail"

const CreditCardPage = ({ data, location }) => {
  const doc = data && data.prismicCreditCard
  if (!doc) return null
  const creditcard = parseCreditCard(doc)

  const { siteMetadata } = data.site
  const { publisherLogo } = data

  const { card_comparison_image, name, card_image, first_publication_date,last_publication_date } = creditcard
  const cardImage = card_comparison_image ? card_comparison_image : card_image

  useEffect(() => {
    addGaEvent({
      ga4Event: "page_view",
      eventParams: {
        page: `/信用卡比較/${creditcard.uid}`,
        page_type: "card",
        page_id: creditcard.prismicId,
        page_title: creditcard.name,
      },
    })
  }, [])

  return (
    <Layout type="credit_card">
      <AppLink path={location.pathname} />
      <SEO
        title={name}
        image={cardImage.url}
      />
      <JsonLd>
        {articleSchema({
          url: urljoin(siteMetadata.siteUrl, linkResolver(creditcard)),
          headline: name,
          image: cardImage.url,
          datePublished: first_publication_date,
          dateModified: last_publication_date,
          author: siteMetadata.title,
          publisher: {
            name: siteMetadata.title,
            logo: urljoin(siteMetadata.siteUrl, publisherLogo.childImageSharp.original.src),
          },
        })}
      </JsonLd>
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
      </Container>
      <CreditCardDetail creditcard={creditcard} />
    </Layout>
  )
}

export default CreditCardPage

export const query = graphql`
  query CreditCardDetailQuery($id: ID!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    publisherLogo: file(relativePath: { eq: "publisher-logo.png" }) {
      childImageSharp {
        original {
          src
        }
      }
    }
    prismicCreditCard(prismicId: { eq: $id }) {
      prismicId
      first_publication_date
      last_publication_date
      uid
      data {
        application_url {
          url
        }
        bank {
          document {
            ... on PrismicBank {
              id
              uid
              prismicId
              data {
                name
                card_comparison_page_filter
              }
            }
          }
        }
        card_comparison_image {
          alt
          url
          fixed(width: 370) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        card_details {
          html
          raw
          text
        }
        card_image {
          alt
          url
          fixed(height: 370) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        card_type
        cash_rebate_or_mile_rebate
        detail_exclusive {
          html
          raw
          text
        }
        display
        display_on_offer_page
        highlight {
          html
          raw
          text
        }
        highlight_image {
          alt
          url
          dimensions {
            width
            height
          }
          fluid(maxWidth: 988) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        important_notes {
          html
          raw
          text
        }
        jetsobee_exclusive
        minimum_annual_salary
        name
        rebate_1
        rebate_1_place
        rebate_2
        rebate_2_place
        rebate_3
        rebate_3_place
        rebate_4
        rebate_4_place
        rebate_5
        rebate_5_place
        sequence
        welcome_gift_details {
          left_column
          right_column
        }
        welcome_gift_highlight_v2 {
          html
          raw
          text
        }
      }
    }
  }
`
