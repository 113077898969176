const articleSchema = ({ url, headline, image, datePublished, dateModified, author, publisher }) => ({
  "@context": "http://schema.org",
  "@type": "Article",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id": url,
  },
  headline,
  image,
  datePublished,
  dateModified,
  author: {
    "@type": "Person",
    name: author,
  },
  publisher: {
    "@type": "Organization",
    name: publisher.name,
    logo: {
      "@type": "ImageObject",
      url: publisher.logo,
    },
  },
})

export default articleSchema
